/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import {
    DocumentalStatusValues,
    MaintenanceColorValues,
    statusVehicles,
    statusVehiclesEnum,
    TVehicle,
    VisualStatusValues,
} from "models/vehicle.interface";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/extensions
import SwiperClass from "swiper/types/swiper-class";
// eslint-disable-next-line import/extensions
import { Swiper, SwiperSlide } from "swiper/react";
import Swipercore, { Navigation, Pagination, Autoplay } from "swiper";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

// css
import "./detailsI.scss";

// imgs
import { ReactComponent as Back } from "design_system/src/static/icons/back.svg";
import { ReactComponent as UserCircle } from "design_system/src/static/icons/user-circle.svg";
import { ReactComponent as Moto } from "design_system/src/static/icons/Motocycle.svg";
import { ReactComponent as Signal } from "design_system/src/static/icons/radio-signal.svg";
import { ReactComponent as Position } from "design_system/src/static/icons/gps.svg";
// import {ReactComponent as Add} from "design_system/src/static/icons/add-bracket.svg";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-text.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Image } from "design_system/src/static/icons/image.svg";
import { ReactComponent as Refresh } from "design_system/src/static/icons/refresh.svg";
import { ReactComponent as Search } from "design_system/src/static/icons/search.svg";
import { ReactComponent as Success } from "design_system/src/static/icons/tick-circle.svg";
import { ReactComponent as Info } from "design_system/src/static/icons/info.svg";

import {
    Button,
    Typography,
    Input,
    DataTable,
    ModalConfirmation,
    SideModal,
    ListTable,
    Pager,
    Select,
} from "design_system/src";
import { StatusPopup } from "components/hocs/statusPopup/statusPopup";
import { has, isArray } from "lodash";
import {
    addFile,
    deleteFile,
    editVehicle,
    getPrices,
    getVehicleByInternalId,
} from "helpers/fetchs/inventory";
// import { useDraggable } from "libs/index";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import { priceFormatFinancial } from "helpers/prices/prices";
// import { priceFormatFinancial, priceMXNTotal } from "helpers/prices/prices";
import { getOzonerByVehicle } from "helpers/fetchs/ozoners";
import { TDataozoner } from "models/ozoner.interface";
import History from "components/hocs/history/History";
import { fetchNotifications } from "helpers/fetchs/notifications";
import moment from "moment";

import {
    editDevice,
    fetchCurrentDevice,
    fetchDevices,
} from "helpers/fetchs/devices";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { TDevice } from "models/devices.interface";
import { Divider } from "@mui/material";
import EditVehicle from "./EditVehicle";
import AddEditDiscount from "../../components/hocs/addEditDiscount/AddEditDiscount";

const colorsMaintenance: any = {
    green: {
        color: "green_300",
        desc: "Para entrega en 24h",
    },
    yellow: {
        color: "yellow_100",
        desc: "Para entrega en 48h",
    },
    blue: {
        color: "secondary_500",
        desc: "Para entrega en 72h",
    },
    red: {
        color: "red_300",
        desc: "Para entrega en 5 dias habiles",
    },
};

const validationItem = (
    valid: boolean,
    validLabel: string,
    invalidLabel: string
) => (
    <>
        {valid ? (
            <Success className="dim_xl text_green_300" />
        ) : (
            <Close className="dim_xl text_red_300" />
        )}
        <Typography
            scale="medium"
            weight="400"
            textColor={valid ? "green_300" : "red_300"}
            className="text_no_break"
        >
            {valid ? validLabel : invalidLabel}
        </Typography>
    </>
);

const informativeMessagge = (
    title: string,
    subtitle: string,
    textColor: any,
    color: string
) => (
    <div className={`p_xs bg_${color} flex_center_col flex_gap_xs m_b_xs`}>
        <div className="flex_center flex_gap_xs ">
            <Info className={`dim_lg text_${textColor}`} />
            <Typography scale="small" weight="600" textColor={textColor}>
                {title}
            </Typography>
        </div>
        <Typography
            scale="xsmall"
            textColor={textColor}
            weight="400"
            className="text_center"
        >
            {subtitle}
        </Typography>
    </div>
);

const detailsData = [
    [
        {
            title: "Color principal",
            name: "color",
            map: {
                yellow: "Amarillo",
                pink: "Rosa",
                purple: "Morado",
                blue: "Azul",
                orange: "Naranja",
                green: "Verde",
                white: "Blanco",
                gray: "Gris",
                red: "Rojo",
                black: "Negro",
            },
        },
        {
            title: "Color secundario",
            name: "secondaryColor",
            map: {
                yellow: "Amarillo",
                pink: "Rosa",
                purple: "Morado",
                blue: "Azul",
                orange: "Naranja",
                green: "Verde",
                white: "Blanco",
                gray: "Gris",
                red: "Rojo",
                black: "Negro",
            },
        },
        {
            title: "Serie vehicular",
            name: "vehicleSN",
        },

        {
            title: "Tarjeta de Circulación",
            name: "registrationCard",
        },
        {
            title: "Motor",
            name: "engineSN",
        },
        {
            title: "Estado documental",
            name: "documentalStatus",
            map: Object.keys(DocumentalStatusValues).reduce((acc,key)=>({...acc,[key]:(DocumentalStatusValues as any)[key].label}),{})
        },
        {
            title: "Estado Visual",
            name: "visualStatus",
            map: Object.keys(VisualStatusValues).reduce((acc,key)=>({...acc,[key]:(VisualStatusValues as any)[key].label}),{})
        },
    ],
    [
        {
            title: "Año",
            name: "year",
            sub: "details",
        },
        {
            title: "Frenos",
            name: "brakeType",
            map: { drum: "Tambor", disc: "Discos" },
        },
        {
            title: "Placa",
            name: "plate",
        },
    ],
];

const hasVehicleOptions = [
    {
        label: "Dispositivo asignado",
        value: "true",
    },
    {
        label: "Dispositivo sin asignar",
        value: "false",
    },
];

const formatName = (name: string) => name.replaceAll(" ", "_");
const displayName = (name: string) => name.replaceAll("_", " ");

function DetailsInventory() {
    const [swiperRef, setSwiperRef] = useState<SwiperClass>();
    Swipercore.use([Navigation, Pagination, Autoplay]);
    const navigate = useNavigate();
    const { internalid } = useParams();
    const [vehicle, setvehicle] = useState<TVehicle | null>();
    const [ozoner, setOzoner] = useState<TDataozoner | undefined>();
    const [loading, setloading] = useState<boolean>(false);
    const [slider, setSlider] = useState({ current: 0 });
    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [openAddEditDiscount, setOpenAddEditDiscount] =
        useState<boolean>(false);
    const [openStatus, setOpenStatus] = useState<boolean>(false);
    const [result, setResult] = useState<TVehicle | null>();
    const [activeDiscount, setActiveDiscount] = useState<any | null>();
    const [detailTab, setDetailTab] = useState<number>(0);

    const [creditTimes, setCreditTimes] = useState<{
        paymentWeek: number;
        paymentWeekBeforeDiscount: number;
        hasDiscount: boolean;
        creditPrice: number;
        creditPriceBeforeDiscount: number;
        capital: number;
        interest: number;
        interestRate: number;
        weeks: number;
      }[]>();
    const [openHistory, setOpenHistory] = useState<boolean>(false);

    const [alarms, setAlarms] = useState([]);

    const [openDevices, setOpenDevices] = useState<boolean>(false);

    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [device, setDevice] = useState<TDevice | null>();
    const [devices, setDevices] = useState<TDevice[]>([]);
    const [hasVehicleFilter, setHasVehicleFilter] = useState(["false"]);

    const [queryDevices, setQueryDevices] = useState("");
    const [loadingDevices, setLoadingDevices] = useState<boolean>(false);

    const [count, setCount] = useState(0);

    // const ref = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
    // const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

    // eslint-disable-next-line no-unused-vars
    const [photos, setPhotos] = useState<any[]>([]);
    const slideTo = (index: any) => {
        if (swiperRef) swiperRef.slideTo(index, 0);
    };
    const changeState = (e: any) => {
        e.stopPropagation();
        setOpenStatus(true);
    };

    useEffect(() => {
        if (internalid) {
            fetchNotifications(
                undefined,
                undefined,
                -1,
                undefined,
                internalid
            ).then((info: any) => {
                setAlarms(info.data);
            });
            fetchCurrentDevice(internalid).then((dev: any) => {
                if (dev?.device) {
                    setDevice(dev.device);
                }
            });
        }
    }, [internalid]);

    const getDevices = () => {
        setLoadingDevices(true);
        let hasVehicle;

        if (
            hasVehicleFilter.length &&
            hasVehicleFilter.length !== hasVehicleOptions.length
        )
            [hasVehicle] = hasVehicleFilter;
        fetchDevices(currentPage, 30, queryDevices, hasVehicle).then(
            (info: any) => {
                setLoadingDevices(false);
                setDevices(info.data);
                setTotalPages(info.pages);
                setCount(info.count);

                setCurrentPage(1);
            }
        );
    };

    const closeDevices = () => {
        setCurrentPage(1);
        setDevices([]);
        setQueryDevices("");
        setOpenDevices(false);
    };
    const openInvDevices = () => {
        setOpenDevices(true);
        getDevices();
    };

    useEffect(() => getDevices(), [hasVehicleFilter, currentPage]);

    const assignDevice = (id: string) => {
        if (vehicle && internalid) {
            editDevice({ vehicle: vehicle._id }, id).then(() => {
                closeDevices();
                navigate(`/inventory/${internalid}`);
                fetchCurrentDevice(internalid).then((dev: any) => {
                    if (dev?.device) {
                        setDevice(dev.device);
                    }
                });
            });
        }
    };

    const fetchVehicle = () => {
        getVehicleByInternalId(internalid)
            .then((vehi: any) => {
                setloading(false);
                setvehicle(vehi);
                if (vehi.images.length > 0) {
                    setPhotos(vehi.images);
                }
            })
            .catch(() => {
                setloading(false);
                Swal.fire({
                    title: "Error!",
                    text: "Internal id no valido",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                }).then(() => {
                    navigate("/inventory");
                });
            });
    };

    useEffect(() => {
        setPhotos([
            {
                url: `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`,
            },
        ]);
        setloading(true);
        fetchVehicle();
    }, []);

    useEffect(() => {
        if (result) {
            if (result.internalId !== internalid) {
                navigate(`/inventory/${result.internalId}`);
            }
            fetchVehicle();
        }
    }, [result]);

    const [hasKM, setHasKM] = useState(false);
    const [hasDays, setHasDays] = useState(false);
    const [hasPrice, setHasPrice] = useState(false);
    const [hasHardwareCost, setHasHardwareCost] = useState(false);
    const [hasSoftwareCost, setHasSoftwareCost] = useState(false);
    const [hasPurchaseCost, setHasPurchaseCost] = useState(false);
    const [hasExtraCost, setHasExtraCost] = useState(false);

    useEffect(() => {
        if (vehicle) {
            getOzonerByVehicle(vehicle._id).then((res) => {
                setOzoner(res);
            });

            
            const discount = vehicle.discounts?.filter(
                (e: any) => e.status === "active"
            )[0];
            
            if (discount) setActiveDiscount(discount);
            
            getPrices(internalid??"", vehicle.salePrices?.map((ele:any)=>ele.weeks)??[]).then((prices) => {
                setCreditTimes(prices.map((price: any) => price.payment));
            });

            setHasKM(vehicle.newVehicle ||
                vehicle.confirmationKM ||
                !!vehicle.details?.milage);
            setHasDays(!(!vehicle.maintenanceDays || vehicle.maintenanceDays === 0));
            setHasPrice(!!vehicle.salePrices?.length &&
                vehicle.salePrices.reduce(
                    (
                        acc: boolean,
                        price: any
                    ) =>
                        acc &&
                        price.paymentWeek > 50,
                    true
                ));
            setHasHardwareCost(!(!vehicle.gpsHardwareCost || vehicle.gpsHardwareCost === 0));
            setHasSoftwareCost(!(!vehicle.gpsMonthlySoftwareCost || vehicle.gpsMonthlySoftwareCost === 0));
            setHasPurchaseCost(!(!vehicle.purchaseCost || vehicle.purchaseCost === 0));
            setHasExtraCost(vehicle.newVehicle || !(!vehicle.extraCost || vehicle.extraCost === 0));
        }
    }, [vehicle]);

    // ---------- handle modal--------------
    const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const handleCloseModal = () => setOpenConfirmation(!openConfirmation);
    const [stateVehicle, setstateVehicle] = useState("");

    const renderModalSubTitle = () =>
        stateVehicle === statusVehiclesEnum.recovered ? (
            <Typography scale="medium" weight="400" textColor="neutral_900">
                Al cambiar a este estado todos los pagos pendientes del crédito
                quedaran anulados esta seguro que quiere hacer el cambio
            </Typography>
        ) : (
            <Typography scale="medium" weight="400" textColor="neutral_900">
                ¿Estás seguro de que quieres cambiar el estado a{" "}
                <span style={{ fontWeight: "bolder" }}>
                    {statusVehicles[stateVehicle as keyof typeof statusVehicles]
                        ?.title ?? stateVehicle}
                    ?
                </span>{" "}
            </Typography>
        );

    const renderModalTitle = () => (
        <Typography scale="large" weight="600" textColor="neutral_900">
            {stateVehicle === statusVehiclesEnum.recovered
                ? "¿Moto Recuperada?"
                : "Cambio de estado"}
        </Typography>
    );

    const handleSetState = () => {
        handleCloseModal();
        if (internalid && stateVehicle !== undefined) {
            editVehicle({ status: stateVehicle }, internalid).then((ele) => {
                setResult(ele);
                setOpenStatus(false);
            });
        }
    };

    const setState = (status: string) => {
        setstateVehicle(status);
        handleCloseModal();
    };

    const handleFile = (e: any) => {
        const file = e.target.files[0];
        if (file.size > 10000000) {
            Swal.fire({
                text: "La imagen no debe ser superior a 10mb",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        } else if (vehicle && internalid) {
            const blob = file.slice(0, file.size, file.type);
            const newFile = new File([blob], formatName(file.name), {
                type: file.type,
            });
            addFile(internalid, newFile).then((newData: any) => {
                setResult(newData);
            });
        }
    };

    const deleteFiles = (id: string) => {
        if (vehicle && internalid) {
            deleteFile(internalid, id).then((newData: any) => {
                setResult(newData);
            });
        }
    };

    const getDetails = (group: number) => {
        const data: any = [];
        if (group === 1) {
            data.push({ label: "Marca", value: vehicle?.brand?.name });
            data.push({ label: "Modelo", value: vehicle?.model?.name });
            data.push({
                label: "Cilindraje",
                value: vehicle?.cylindersCapacity,
            });
            data.push({
                label: "Kilometraje",
                value: vehicle?.confirmationKM
                    ? "Por confirmar"
                    : vehicle?.details?.milage ?? 0,
            });
        }
        if (group === 0){
            data.push({ label: "Precio GPS", value: priceFormatFinancial(vehicle?.gpsHardwareCost??0) });
            data.push({ label: "Precio software GPS (Mensual)", value: priceFormatFinancial(vehicle?.gpsMonthlySoftwareCost??0) });
        }
        return [
            ...data,
            ...detailsData[group].map((field: any) => ({
                label: field.title,
                value: `${
                    (field.map
                        ? field.map[
                              field.sub
                                  ? (vehicle as any)[field.sub]
                                      ? (vehicle as any)[field.sub][field.name]
                                      : "--"
                                  : (vehicle as any)[field.name]
                          ]
                        : field.sub
                        ? (vehicle as any)[field.sub]
                            ? (vehicle as any)[field.sub][field.name]
                            : "--"
                        : (vehicle as any)[field.name]) ?? ""
                }`,
            })),
        ];
    };

    const goToOzoner = () => {
        if (ozoner && vehicle) {
            const findForm= ozoner.financialForm.find((form) => form.vehicleId === vehicle.internalId);
            navigate(`/ozoner/${ozoner._id}${findForm?`?formId=${findForm._id}`:""}`);
        }
        else{
            toasterNotification({
                toasterType: toasterOptions.error,
                msg: "No fue posible redirigir al ozoner",
                style: "light",
              });
        }
    };

    return (
        <div className=" display_flex detailsView overflow_hidden">
            {!loading && vehicle ? (
                <>
                    <div className="flex_basis_0 flex_grow_1 overflow_x_hidden">
                        <div className="">
                            <div className="">
                                <Button
                                    variant="icon"
                                    icon={<Back />}
                                    subvariant="edit"
                                    scale="small"
                                    onClick={() => navigate(-1)}
                                    className="m_b_lg"
                                />
                                <div
                                    className="display_flex flex_col br_l_md"
                                    style={{ border: "1px solid #ECEEEF" }}
                                >
                                    <div className="display_flex flex_gap_md flex_align_center p_xs">
                                        <LightTooltip
                                            title={
                                                 vehicle.maintenanceDays?`Mantenimiento de ${vehicle.maintenanceDays} dias` :
                            "No dispone de informacion"
                                            }
                                        >
                                            <Moto
                                                className={`dim_lg text_${
                                                    MaintenanceColorValues[vehicle.maintenanceColor]
                                    ?.color ?? "primary_300"
                                                }`}
                                            />
                                        </LightTooltip>
                                        <LightTooltip
                                            title={
                                                vehicle.gpsStatus ===
                                                "withSignal"
                                                    ? "Reporta señal GPS"
                                                    : "No reporta señal GPS"
                                            }
                                        >
                                            <Signal
                                                className={`dim_lg text_${
                                                    vehicle.gpsStatus ===
                                                    "withSignal"
                                                        ? "green_300"
                                                        : "neutral_300"
                                                }`}
                                            />
                                        </LightTooltip>
                                        <Typography
                                            scale="heading2"
                                            weight="600"
                                            textColor="neutral_900"
                                        >
                                            {vehicle.internalId}
                                        </Typography>
                                        <div>
                                            <div className="display_flex flex_gap_xs flex_align_center">
                                                <Typography
                                                    scale="large"
                                                    weight="600"
                                                    textColor="primary_300"
                                                    component="span"
                                                >
                                                    {`${vehicle.brand.name} ${
                                                        vehicle.model.name
                                                    } ${
                                                        vehicle.suffix
                                                            ? vehicle.suffix
                                                            : ""
                                                    } ${
                                                        vehicle.cylindersCapacity
                                                            ??""
                                                    }`}
                                                </Typography>
                                            </div>
                                            <div className="display_flex flex_gap_xs flex_align_center">
                                                <Position className="dim_lg text_neutral_900" />
                                                <Typography
                                                    scale="large"
                                                    weight="400"
                                                    textColor="neutral_900"
                                                    component="span"
                                                >
                                                    {`${vehicle.city?.name} / ${vehicle.hub?.name}`}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="p_xs bg_neutral_300 display_flex flex_align_center flex_gap_xs"
                                        style={{
                                            borderTop: "1px solid #ECEEEF",
                                            borderBottomLeftRadius: "1rem",
                                        }}
                                    >
                                        <UserCircle className="text_primary_300" />
                                        <Typography
                                            scale="medium"
                                            weight="400"
                                            className="text_neutral_700"
                                        >
                                            {ozoner
                                                ? ozoner.name
                                                : "Sin usuario"}
                                        </Typography>
                                        {ozoner && (
                                            <Button
                                                scale="small"
                                                variant="tint"
                                                onClick={goToOzoner}
                                            >
                                                Ver ozoner
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className=" p_r_md">
                                <Typography
                                    scale="large"
                                    weight="600"
                                    textColor="neutral_900"
                                >
                                    Descripción del vehículo
                                </Typography>
                                <hr className="m_y_md bg_neutral_500" />
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    textColor="neutral_700"
                                    className="m_b_md"
                                >
                                    {vehicle.description}
                                </Typography>
                                <div className="dso_card bg_neutral_0 br_xs p_md m_b_xxl">
                                    <Swiper
                                        navigation
                                        onSwiper={setSwiperRef}
                                        className=" w_100_per m_b_md"
                                        onSlideChange={() => {
                                            setSlider({
                                                current: swiperRef
                                                    ? swiperRef.activeIndex
                                                    : 0,
                                            });
                                        }}
                                    >
                                        {photos.map((value, index) => (
                                            <SwiperSlide
                                                key={`slide-${index}`}
                                                className="overflow_hidden br_md flex_center"
                                            >
                                                <img
                                                    src={value.url}
                                                    alt="moto"
                                                    className="swiper_img"
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="image-section">
                                        {[0, 1, 2, 3].map((_, index) => (
                                            <div
                                                className={`image-cont ${
                                                    photos[index]
                                                        ? "dso_card"
                                                        : "empty"
                                                }  ${
                                                    slider.current === index
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() => slideTo(index)}
                                                role="button"
                                                onKeyPress={() =>
                                                    slideTo(index)
                                                }
                                                tabIndex={0}
                                                key={`photo${index}`}
                                            >
                                                <div className="image-basis">
                                                    {photos[index] && (
                                                        <>
                                                            <img
                                                                src={
                                                                    photos[
                                                                        index
                                                                    ].url
                                                                }
                                                                alt="moto"
                                                                className="center"
                                                            />
                                                            {index === 3 &&
                                                                photos.length >
                                                                    4 && (
                                                                    <div className="cover_img flex_center">
                                                                        <Typography
                                                                            scale="heading1"
                                                                            weight="600"
                                                                            textColor="primary_300"
                                                                            className="text_center"
                                                                        >
                                                                            +
                                                                            {photos.length -
                                                                                3}
                                                                        </Typography>
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="display_flex flex_gap_md m_b_md">
                                    <Button
                                        variant="outline"
                                        scale="small"
                                        icon={<Edit />}
                                        className="flex_basis_0 flex_grow_1"
                                        onClick={() => setOpenDetails(true)}
                                    >
                                        Editar
                                    </Button>
                                    <Button
                                        variant="outline"
                                        scale="small"
                                        icon={<Edit />}
                                        className="flex_basis_0 flex_grow_1"
                                        onClick={() =>
                                            setOpenAddEditDiscount(true)
                                        }
                                    >
                                        Generar Descuento
                                    </Button>
                                </div>
                                <Button
                                    scale="small"
                                    variant="tint"
                                    icon={<Refresh />}
                                    onClick={() => setOpenHistory(true)}
                                    className="w_100_per  m_b_xxl"
                                >
                                    Historial de cambios
                                </Button>
                                <Typography
                                    scale="large"
                                    weight="600"
                                    textColor="neutral_900"
                                >
                                    Especificaciones del vehiculo
                                </Typography>
                                <hr className="m_y_md bg_neutral_500" />
                                <DataTable
                                    data={getDetails(0)}
                                    weightLabels={2}
                                    className="m_b_xl"
                                />
                                <DataTable
                                    data={getDetails(1)}
                                    weightLabels={2}
                                    className="m_b_xl"
                                />
                                <Typography
                                    scale="large"
                                    weight="600"
                                    textColor="neutral_900"
                                >
                                    Detalles y garantías
                                </Typography>
                                <hr className="m_y_md bg_neutral_500" />
                                <div className="display_flex w_80_per">
                                    <div
                                        className={`flex_basis_0 flex_grow_1 cursor_pointer p_x_lg p_y_sm bg_${
                                            detailTab === 0
                                                ? "primary_300"
                                                : "neutral_300"
                                        }`}
                                        onClick={() => setDetailTab(0)}
                                        role="button"
                                        onKeyPress={() => setDetailTab(0)}
                                        tabIndex={0}
                                    >
                                        <Typography
                                            scale="medium"
                                            weight="600"
                                            textColor={
                                                detailTab === 0
                                                    ? "neutral_0"
                                                    : "neutral_700"
                                            }
                                        >
                                            Detalles
                                        </Typography>
                                    </div>
                                    <div
                                        className={`flex_basis_0 flex_grow_1 cursor_pointer p_x_lg p_y_sm bg_${
                                            detailTab === 1
                                                ? "primary_300"
                                                : "neutral_300"
                                        }`}
                                        onClick={() => setDetailTab(1)}
                                        role="button"
                                        onKeyPress={() => setDetailTab(1)}
                                        tabIndex={0}
                                    >
                                        <Typography
                                            scale="medium"
                                            weight="600"
                                            textColor={
                                                detailTab === 1
                                                    ? "neutral_0"
                                                    : "neutral_700"
                                            }
                                        >
                                            Garantías
                                        </Typography>
                                    </div>
                                </div>
                                <div className="border_solid border_neutral_300 border_1 p_lg m_b_xl">
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        textColor="neutral_700"
                                    >
                                        {detailTab === 0
                                            ? vehicle.detail
                                            : vehicle.warranty}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex_basis_0 flex_grow_1 overflow_x_hidden bg_neutral_300 display_flex flex_col">
                        <div className="p_xl static_info_vehicle">
                            <div className="display_flex flex_justify_between m_b_md">
                                <Typography
                                    scale="large"
                                    weight="600"
                                    textColor="neutral_900"
                                    className=""
                                >
                                    Información general
                                </Typography>
                                <Button
                                    icon={<Position />}
                                    scale="small"
                                    variant="outline"
                                    onClick={openInvDevices}
                                >
                                    {device
                                        ? `GPS: ${device.imei}`
                                        : "Asignar GPS"}
                                </Button>
                            </div>
                            <div className="bg_neutral_400 br_sm  m_b_xxl">
                                <div className="bg_neutral_0 br_t_sm display_flex p_y_sm p_x_lg flex_align_center flex_justify_between">
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        textColor="neutral_900"
                                    >
                                        Estado del Vehículo
                                    </Typography>
                                    <div className="flex_center flex_gap_xs">
                                        <div
                                            className={`
                      dso_chip_large
                      pos_relative
                      text_neutral_0 
                      bg_${
                          statusVehicles[
                              vehicle.status as keyof typeof statusVehicles
                          ]?.color
                      }_nocontrast`}
                                        >
                                            {statusVehicles[
                                                vehicle.status as keyof typeof statusVehicles
                                            ]?.title ?? vehicle.status}
                                            <StatusPopup
                                                setOpen={setOpenStatus}
                                                placementY="bottom"
                                                open={openStatus}
                                                onChange={setState}
                                                labels={
                                                    (
                                                        statusVehicles[
                                                            vehicle.status as keyof typeof statusVehicles
                                                        ]
                                                            ?.availableStatus as statusVehiclesEnum[]
                                                    )?.reduce(
                                                        (acc: any, key) => ({
                                                            ...acc,
                                                            [key]: statusVehicles[
                                                                key
                                                            ],
                                                        }),
                                                        {}
                                                    ) ?? {}
                                                }
                                            />
                                        </div>
                                        {vehicle.status !==
                                            statusVehiclesEnum.draft && (
                                            <Button
                                                onClick={changeState}
                                                variant="icon"
                                                icon={<Edit />}
                                                subvariant="edit"
                                                scale="small"
                                            />
                                        )}
                                        <ModalConfirmation
                                            handleClose={handleCloseModal}
                                            openConfirmation={openConfirmation}
                                            onConfirmationCallBack={() =>
                                                handleSetState()
                                            }
                                            isConfirmationLoading={
                                                isConfirmationLoading
                                            }
                                            title={renderModalTitle()}
                                            subtitle={renderModalSubTitle()}
                                            copyReject="Cancelar"
                                            copyConfirm="Cambiar"
                                        />
                                    </div>
                                </div>
                                <div className="p_x_lg p_t_xxxl p_b_xl display_flex flex_gap_sm flex_align_center">
                                    <div
                                        className="display_flex flex_gap_xs overflow_x_auto p_b_md"
                                        // ref={ref}
                                        // {...events}
                                    >
                                        <div className="dso_card bg_neutral_0 p_md w_fit border_primary_200 border_dotted display_flex flex_col flex_justify_between pos_relative">
                                            <Typography
                                                scale="xsmall"
                                                weight="600"
                                                className="text_center m_b_md pdf_name"
                                                textColor="primary_300"
                                            >
                                                Añadir Documento
                                            </Typography>
                                            <div
                                                className="dso_card_img m_b_md bg_neutral_200 w_fit center_x display_flex overflow_hidden border_primary_300"
                                                onClick={() => 1}
                                                onKeyPress={() => 1}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                <Image className="dim_xl m_lg text_primary_300 border_transparent" />
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/png, image/jpeg, application/pdf"
                                                onChange={handleFile}
                                                className="input-file"
                                            />
                                        </div>
                                        {vehicle.documents &&
                                            vehicle.documents.map(
                                                (ele: any, i: number) => (
                                                    <div
                                                        key={`photo${i}`}
                                                        className="dso_card bg_neutral_0 p_md w_fit pos_relative display_flex flex_col flex_justify_between"
                                                    >
                                                        <Close
                                                            className="text_red_300 dim_lg delete_photo"
                                                            onClick={() => {
                                                                deleteFiles(
                                                                    ele.name
                                                                );
                                                            }}
                                                        />
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="600"
                                                            textColor="neutral_900"
                                                            className="text_center m_b_md pdf_name"
                                                        >
                                                            {displayName(
                                                                ele.name
                                                            )}
                                                        </Typography>
                                                        <div
                                                            className="dso_card_img m_b_md bg_neutral_200 w_fit center_x display_flex overflow_hidden cursor_pointer"
                                                            onClick={() =>
                                                                window.open(
                                                                    ele.url,
                                                                    "_blank"
                                                                )
                                                            }
                                                            onKeyPress={() =>
                                                                window.open(
                                                                    ele.url,
                                                                    "_blank"
                                                                )
                                                            }
                                                            role="button"
                                                            tabIndex={0}
                                                        >
                                                            <Image className="dim_xl m_lg" />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>
                            </div>
                            {vehicle.status === statusVehiclesEnum.draft && (
                                <div className="display_flex flex_col flex_gap_md m_b_xxl">
                                    {internalid &&
                                        /[a-zA-Z]/.test(
                                            internalid[internalid.length - 1]
                                        ) &&
                                        ((new Date().getFullYear() - (vehicle?.details?.year ?? new Date().getFullYear())) >
                                            2) &&
                                        informativeMessagge(
                                            "Considerada moto quedada",
                                            "Las motos recuperadas y que tienen mas de 2 años deberian pasar a quedadas",
                                            "neutral_800",
                                            "yellow_100"
                                        )}
                                    <div className="display_flex flex_gap_xs flex_align_center">
                                        {validationItem(
                                            hasKM,
                                            "Kilometraje asignado",
                                            "Kilometraje sin asignar"
                                        )}
                                    </div>
                                    <div className="display_flex flex_gap_xs flex_align_center">
                                        {validationItem(
                                            hasPrice,
                                            "Precio asignado",
                                            "Precio sin asignar"
                                        )}
                                    </div>
                                    <div className="display_flex flex_gap_xs flex_align_center">
                                        {validationItem(
                                            hasDays,
                                            "Dias de mantenimiento asignado",
                                            "Dias de mantenimiento sin asignar"
                                        )}
                                    </div>
                                    <div className="display_flex flex_gap_xs flex_align_center">
                                        {validationItem(
                                            hasHardwareCost,
                                            "Precio Hardware GPS asignado",
                                            "Precio Hardware GPS sin asignar"
                                        )}
                                    </div>
                                    <div className="display_flex flex_gap_xs flex_align_center">
                                        {validationItem(
                                            hasSoftwareCost,
                                            "Precio Software GPS asignado",
                                            "Precio Software GPS sin asignar"
                                        )}
                                    </div>
                                    <div className="display_flex flex_gap_xs flex_align_center">
                                        {validationItem(
                                            hasPurchaseCost,
                                            "Precio Compra asignado",
                                            "Precio Compra sin asignar"
                                        )}
                                    </div>
                                    <div className="display_flex flex_gap_xs flex_align_center">
                                        {validationItem(
                                            hasExtraCost,
                                            "Gastos extras asignado",
                                            "Gastos extras sin asignar"
                                        )}
                                    </div>
                                    <Button
                                        scale="small"
                                        disabled={
                                            !hasKM ||
                                            !hasDays ||
                                            !hasPrice ||
                                            !hasHardwareCost ||
                                            !hasSoftwareCost ||
                                            !hasPurchaseCost ||
                                            !hasExtraCost 
                                        }
                                        onClick={() =>
                                            setState(
                                                statusVehiclesEnum.available
                                            )
                                        }
                                    >
                                        Pasar moto a disponible
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            setState(statusVehiclesEnum.left)
                                        }
                                        scale="small"
                                        color="grey"
                                        disabled={
                                            !(
                                                vehicle.newVehicle ||
                                                vehicle.confirmationKM ||
                                                !!vehicle.details?.milage
                                            ) ||
                                            !(
                                                !!vehicle.salePrices?.length &&
                                                vehicle.salePrices.reduce(
                                                    (
                                                        acc: boolean,
                                                        price: any
                                                    ) =>
                                                        acc &&
                                                        price.paymentWeek > 50,
                                                    true
                                                )
                                            )
                                        }
                                    >
                                        Pasar moto a quedadas
                                    </Button>
                                </div>
                            )}
                            <Typography
                                scale="large"
                                weight="600"
                                textColor="neutral_900"
                                className="m_b_lg"
                            >
                                Plan de pagos disponibles
                            </Typography>
                            <div className="display_flex flex_gap_lg flex_justify_center m_b_lg">
                                {creditTimes?.map((price) => (
                                    <div
                                        className="dso_card bg_neutral_0 br_sm"
                                        key={`price-${price.weeks}`}
                                    >
                                        <div className="bg_primary_300 p_lg br_t_sm">
                                            <Typography
                                                scale="medium"
                                                weight="600"
                                                textColor="neutral_0"
                                                className="text_center"
                                            >
                                                {price.weeks} semanas
                                            </Typography>
                                        </div>
                                        <div className="p_xs p_t_sm">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_900"
                                                className="text_center m_b_xxs"
                                            >
                                                Pago semanal
                                            </Typography>
                                            {price?.hasDiscount && (
                                                <Typography
                                                    scale="large"
                                                    weight="600"
                                                    className="text_center text_red_300 text_decoration_line-through"
                                                >
                                                    {priceFormatFinancial(
                                                        price?.paymentWeekBeforeDiscount,
                                                        0
                                                    )}
                                                </Typography>
                                            )}
                                            <Typography
                                                scale="heading3"
                                                weight="600"
                                                textColor="green_300"
                                                className="text_center m_b_xxs"
                                            >
                                                {priceFormatFinancial(
                                                    price.paymentWeek,
                                                    0
                                                )}
                                            </Typography>
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_900"
                                                className="text_center m_b_xxs"
                                            >
                                                Tasa de interés
                                            </Typography>
                                            <Typography
                                                scale="medium"
                                                weight="600"
                                                textColor="neutral_900"
                                                className="text_center m_b_xs"
                                            >
                                                {price.interestRate}%
                                            </Typography>
                                            <div className="bg_neutral_300 br_b_sm p_x_lg p_y_sm">
                                                <Typography
                                                    scale="small"
                                                    weight="600"
                                                    textColor="neutral_1000"
                                                    className="text_center"
                                                >
                                                    Precio de venta
                                                </Typography>
                                                {price?.hasDiscount && (
                                                    <Typography
                                                        scale="small"
                                                        weight="600"
                                                        className="text_center text_red_300 text_decoration_line-through"
                                                    >
                                                        {priceFormatFinancial(
                                                            price?.creditPriceBeforeDiscount,
                                                            0
                                                        )}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    scale="large"
                                                    weight="600"
                                                    textColor="green_300"
                                                    className="text_center"
                                                >
                                                    {priceFormatFinancial(
                                                        price.creditPrice,
                                                        0
                                                    )}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="bg_neutral_400 br_sm  m_b_xxl">
                                <div className="bg_neutral_0 br_t_sm display_flex p_y_sm p_x_lg flex_align_center flex_justify_between">
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        textColor="neutral_900"
                                    >
                                        Alertas del Vehículo
                                    </Typography>
                                </div>
                                <div className="p_lg p_r_sm display_flex flex_gap_sm flex_col ">
                                    <div className="alarms_section p_r_sm">
                                        {alarms.map((alarm: any) => (
                                            <div
                                                className="p_x_lg p_y_sm br_md m_b_xs bg_neutral_0 display_flex flex_gap_lg"
                                                key={alarm._id}
                                            >
                                                {!alarm.read && (
                                                    <div className="dim_sm br_circle bg_red_300" />
                                                )}
                                                <div className="flex_grow_1">
                                                    <div className="display_flex flex_align_center m_b_md flex_justify_between ">
                                                        <Typography
                                                            scale="small"
                                                            weight="600"
                                                            textColor="neutral_900"
                                                        >
                                                            {alarm.title}
                                                        </Typography>
                                                        <Typography
                                                            scale="xsmall"
                                                            weight="600"
                                                            textColor="neutral_600"
                                                        >
                                                            {moment(
                                                                alarm.createdAt
                                                            )
                                                                .utcOffset(-360)
                                                                .format(
                                                                    "DD/MM/YYYY HH:mm"
                                                                )}
                                                        </Typography>
                                                    </div>
                                                    <Typography
                                                        scale="xsmall"
                                                        weight="400"
                                                        textColor="neutral_600"
                                                    >
                                                        {alarm.description}
                                                    </Typography>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div>Cargando...</div>
            )}
            <EditVehicle
                open={openDetails}
                setOpen={setOpenDetails}
                values={vehicle}
                setResult={setResult}
            />
            {openHistory && vehicle?._id && (
                <History
                    entityId={vehicle._id}
                    open={openHistory}
                    setOpen={setOpenHistory}
                />
            )}
            {creditTimes && creditTimes.length ? (
                <AddEditDiscount
                    open={openAddEditDiscount}
                    vehicle={vehicle}
                    setOpen={setOpenAddEditDiscount}
                    setResult={setResult}
                    initials={activeDiscount}
                    creditTimes={creditTimes}
                />
            ) : null}
            {openDevices ? (
                <SideModal
                    open={openDevices}
                    setOpen={closeDevices}
                    size="normal"
                >
                    <div className="flex_content_col">
                        <div className="flex_header">
                            <Typography
                                scale="heading4"
                                weight="600"
                                textColor="neutral_900"
                                className="m_b_md"
                            >
                                Asignar GPS
                            </Typography>
                            <Divider />
                            <div className="display_flex flex_gap_md w_100_per flex_align_center">
                                <Input
                                    title=""
                                    placeholder="Búsqueda"
                                    className="flex_1"
                                    name="search"
                                    type="text"
                                    value={queryDevices}
                                    onChange={(e: any) =>
                                        setQueryDevices(e.target.value)
                                    }
                                />
                                <Button
                                    type="submit"
                                    scale="small"
                                    icon={<Search />}
                                    className=""
                                    onClick={getDevices}
                                    variant="icon"
                                    subvariant="fill"
                                />
                            </div>
                        </div>
                        <div className="m_b_md">
                            <Select
                                fixedPlaceholder
                                variant="checkbox"
                                placeholder="Seleccione estado"
                                options={hasVehicleOptions}
                                value={hasVehicleFilter}
                                onChange={(e: any) => {
                                    setHasVehicleFilter(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex_body p_r_xs">
                            {loadingDevices ? (
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    textColor="neutral_900"
                                >
                                    Cargando Dispositivos
                                </Typography>
                            ) : devices?.length ? (
                                <div className="display_flex flex_col flex_gap_xs">
                                    {devices.map((dev) => (
                                        <div
                                            className="br_xs border_1 border_solid border_neutral_400 p_md display_flex flex_gap_md flex_align_center cursor_pointer"
                                            key={dev._id}
                                            role="button"
                                            onClick={() =>
                                                assignDevice(dev._id)
                                            }
                                            onKeyDown={() =>
                                                assignDevice(dev._id)
                                            }
                                            tabIndex={0}
                                        >
                                            <Position className="dim_md" />
                                            <Typography
                                                scale="medium"
                                                weight="400"
                                                className=""
                                            >
                                                IMEI: {dev.imei ?? "--"}
                                            </Typography>
                                            <Typography
                                                scale="medium"
                                                weight="600"
                                                className="flex_1 text_right"
                                            >
                                                {dev.phone ?? "--"}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    textColor="neutral_900"
                                >
                                    No se encontraron dispositivos
                                </Typography>
                            )}
                        </div>
                        <div className="flex_footer">
                            <Pager
                                totalPages={totalPages}
                                setPage={setCurrentPage}
                                page={currentPage}
                                count={count}
                                className="w_fit"
                            />
                        </div>
                    </div>
                </SideModal>
            ) : null}
        </div>
    );
}

export default DetailsInventory;
